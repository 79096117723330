import {Injectable, NgZone} from '@angular/core'
import {Subject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PageLayoutService {

  $layoutChange = new Subject<PageLayout>()

  constructor(private ngZone: NgZone) {
    window.addEventListener('resize', () => {
      this.ngZone.run(() => this.onresize())
    })
  }

  getCurrentLayout(): PageLayout {
    const maxWindowWidth = 1920
    const minWindowWidth = 100

    const sideCalendarWidth = 215
    const distanceBetweenContentAndWindowBorder = document.documentElement.clientWidth < maxWindowWidth ? 0 : (document.documentElement.clientWidth - maxWindowWidth) / 2
    const pagePadding = 50

    const headerHeight = 100
    const topicRowHeight = 110
    const footerHeight = 135

    const rightSidebarWidth = 2 * pagePadding + sideCalendarWidth
    const scrollbarThreshold = 20
    const maxContentWidth = maxWindowWidth - rightSidebarWidth - scrollbarThreshold

    const minContentWidth = minWindowWidth - rightSidebarWidth - scrollbarThreshold

    const scalingContentWidth = document.documentElement.clientWidth - rightSidebarWidth - scrollbarThreshold
    const mainContentWidth = Math.max(minContentWidth, Math.min(scalingContentWidth, maxContentWidth))

    const widthBeforeDaysSection = (mainContentWidth - pagePadding) * 0.3

    const widthOfDaysSection = (mainContentWidth - pagePadding) * 0.7

    const result: PageLayout = {
      maxWindowWidth: maxWindowWidth,

      mainContentWidth: mainContentWidth,

      rightSidebarWidth: rightSidebarWidth,
      sideCalendarWidth: sideCalendarWidth,
      distanceBetweenContentAndWindowBorder: distanceBetweenContentAndWindowBorder,

      pagePadding: pagePadding,

      headerHeight: headerHeight,
      topicRowHeight: topicRowHeight,
      footerHeight: footerHeight,

      widthBeforeDaysSection: widthBeforeDaysSection,

      widthOfDaysSection: widthOfDaysSection,

      insideDaysSectionWidthBeforeDays: widthOfDaysSection * 0.1,
      insideDaysSectionWidthOfDays: widthOfDaysSection * 0.85,
      insideDaysSectionWidthAfterDays: widthOfDaysSection * 0.05,

      smartAddingInputWidth: widthBeforeDaysSection * 0.90,
      spaceBetweenSmartAddingInputAndPager: widthBeforeDaysSection * 0.1,

    }
    return result
  }

  private onresize() {
    this.$layoutChange.next(this.getCurrentLayout())
  }
}


export interface PageLayout {
  maxWindowWidth: number

  mainContentWidth: number

  rightSidebarWidth: number
  sideCalendarWidth: number
  distanceBetweenContentAndWindowBorder: number

  pagePadding: number

  headerHeight: number
  topicRowHeight: number
  footerHeight: number

  widthBeforeDaysSection: number

  widthOfDaysSection: number

  insideDaysSectionWidthBeforeDays: number
  insideDaysSectionWidthOfDays: number
  insideDaysSectionWidthAfterDays: number

  smartAddingInputWidth: number
  spaceBetweenSmartAddingInputAndPager: number
}
